import { useContext } from 'react';
import { useState } from 'react';
import { GlobalStateContext } from '../../globalState';
import { useActor } from '@xstate/react';
import moment from 'moment';
import Sparkle from 'react-sparkle'

// How long to highlight the main time to leave for the airport, in milliseconds
const HIGHLIGHT_TIME_MS:number = 1500;

export const MainTimeSummary = () => {
    const globalServices = useContext(GlobalStateContext);
    const [service,] = useActor(globalServices.persistedLeaveWhenService);

    let leaveTime = moment((service.context.parameters.results as any).leaveTime);
    let leaveTimeFormatted = leaveTime.format('h:mm A');

    const [highlightDisplay, setHighlightDisplay] = useState(true);

    setTimeout(() => setHighlightDisplay(false),HIGHLIGHT_TIME_MS);

    return (
        <div className="mainLoadingSummary text-4xl bg-slate-800 rounded-lg color text-slate-50 p-10 mb-8 relative">Leave at {leaveTimeFormatted} {highlightDisplay && <Sparkle flickerSpeed={'slowest'} maxSize={25} count={10} fadeOutSpeed={25} color={'#FFF'}/>}</div>
    );
};