import { BrandingHeader } from '../BrandingHeader/BrandingHeader';
import { InlineTextAd } from '../InlineTextAd/InlineTextAd';
import { DisclaimerInline } from '../DisclaimerInline/DisclaimerInline';
import { MainTimeSummary } from '../MainTimeSummary/MainTimeSummary';
import { OriginSummary } from '../OriginSummary/OriginSummary';
import { SummaryCheckIn } from '../SummaryCheckIn/SummaryCheckIn';
import { SummaryDepartureTime } from '../SummaryDepartureTime/SummaryDepartureTime';
import { SummaryPadding } from '../SummaryPadding/SummaryPadding';
import { SummarySecurity } from '../SummarySecurity/SummarySecurity';
import { SummaryTransportation } from '../SummaryTransportation/SummaryTransportation';

export const Results = () => {
  return (<div>
        <BrandingHeader></BrandingHeader>
        <div className="upperConfigurationSection">
          <OriginSummary></OriginSummary>
        </div>
        <MainTimeSummary></MainTimeSummary>
        <div className="lowerTimeline">
          <SummaryTransportation></SummaryTransportation>
          <SummaryCheckIn></SummaryCheckIn>
          <SummarySecurity></SummarySecurity>
          <SummaryPadding></SummaryPadding>
          <SummaryDepartureTime></SummaryDepartureTime>
          <InlineTextAd></InlineTextAd>
          <DisclaimerInline></DisclaimerInline>
        </div>
      </div>
)};