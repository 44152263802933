import { useContext } from 'react';
import { GlobalStateContext } from '../../globalState';
import { useActor } from '@xstate/react';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export const SummaryDepartureTime = () => {
  const globalServices = useContext(GlobalStateContext);
  const [service,] = useActor(globalServices.persistedLeaveWhenService);

  let departureTime = moment(service.context.parameters.departureTime);
  let departureTimeFormatted = departureTime.format('h:mm A');

  return (<div className="p-4 pt-2 m-6 mt-2 rounded-lg bg-gray-800 text-slate-300 text-lg opacity-80">
        <FontAwesomeIcon className="mt-3" icon={faPlaneDeparture} /> Flight Leaves at {departureTimeFormatted}
      </div>
)};