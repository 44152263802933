import { useContext } from 'react';
import { GlobalStateContext } from '../../globalState';
import { useActor } from '@xstate/react';

export const OriginSummary = () => {
  const globalServices = useContext(GlobalStateContext);
  const [service, send] = useActor(globalServices.persistedLeaveWhenService);

  let goToStart = () => {
    send({type:'RESETHOME'});
  };

  let flightName = service?.context?.parameters?.flight;
  let airport = service?.context?.parameters?.departureAirportName;

  return (<div className="flex flex-row  mb-8 mt-4 pl-4 pr-4">
        <div className="basis-3/4 text-left">
          <div className="text-sm opacity-75">Departing Flight</div>
          <div>{flightName}</div>
          <div className='lowercase inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3'>{airport}</div>
        </div>
        <div className="basis-1/4">
          <button onClick={goToStart} className="
          btn text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none h-9 py-2 px-4">Edit...</button>
        </div>
      </div>
)};