import { useContext } from 'react';
import { GlobalStateContext } from '../../globalState';
import { useActor } from '@xstate/react';

export const DetailBackButton = () => {
  const globalServices = useContext(GlobalStateContext);
  const [, send] = useActor(globalServices.persistedLeaveWhenService);

  let goToMainScreen = () => {
    send({type:'BACK'});
  };

return (
          <button onClick={goToMainScreen} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Back</button>
)};