import logo from './../../logo.svg';
import React, { useContext, useState } from 'react';
import { GlobalStateContext } from '../../globalState';
import { useActor } from '@xstate/react';
import { FlightLookup } from '../FlightLookup/FlightLookup'
import { FlightMatch } from '../../../api/types/FlightMatch'
import Sparkle from 'react-sparkle'

export const Start = () => {
  const globalServices = useContext(GlobalStateContext);
  const [service, send] = useActor(globalServices.persistedLeaveWhenService);
  const [flight, setFlight] = useState({} as FlightMatch);

  function startSearch() {
    service.context.parameters.flight = flight.fullName;
    service.context.parameters.departureAirportName = flight.departureAirportName;
    service.context.parameters.originAirportLocation = flight.departureCoordinates;
    service.context.parameters.departureTime = flight.departureTime;
    send({type:'LOOKUP' });
  }

  function handleFlightChange(m:FlightMatch)
  {
    setFlight(m);
  }

  return (<header className="App-header">
      <div><img src={logo} className="App-logo inline" alt="logo" /></div>
      <p className="text-sm text-slate-100 pb-8 italic">
        Let's find out when you should leave for the airport!
      </p>
      <div className="pb-8">
        <FlightLookup onFlightChange={handleFlightChange}></FlightLookup>
      </div>
      <button type="button" className="relative core-start-button grow btn py-15 text-lg text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group disabled:opacity-25" disabled={!flight.valid} onClick={startSearch}>Start <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>{flight?.valid && <Sparkle flickerSpeed={'slowest'} maxSize={18} count={5} fadeOutSpeed={25} color={'#FFF'}/>}</button>
      </header>
)};