import { createContext } from 'react';
import { useInterpret } from '@xstate/react';
import { leaveWhenMachine } from './leaveWhenMachine';
import { InterpreterFrom } from 'xstate';
//import { inspect } from '@xstate/inspect';
//import { interpret } from 'xstate';

// Create state machine, keeping all state in local storage
// & restoring from local storage on load
const persistedLeaveWhenMachine = leaveWhenMachine.withConfig(
  {
    actions: {
      persist: (ctx) => {
        try {
          let preserialize = Object.assign({},ctx.parameters);
          localStorage.setItem("leavewhen-state", JSON.stringify(preserialize));
          //console.log(JSON.stringify(preserialize));
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
  {
    parameters: (() => {
      try {
        //console.log('restoring state machine');
        //console.log(JSON.parse(localStorage.getItem("leavewhen-state")!));
        return JSON.parse(localStorage.getItem("leavewhen-state")!) || [];
      } catch (e) {
        console.error(e);
        console.log('unable to reload state');
        return [];
      }
    })()
  }
);

/*
const service = interpret(leaveWhenMachine, { devTools: true });
inspect({
  iframe: document.querySelector('iframe.debugging-iframe') as any
});
*/

export const GlobalStateContext = createContext({persistedLeaveWhenService: {} as InterpreterFrom<typeof persistedLeaveWhenMachine>});

export const GlobalStateProvider = (props:any) => {
  const persistedLeaveWhenService = useInterpret(persistedLeaveWhenMachine);

  return (
    <GlobalStateContext.Provider value={{ persistedLeaveWhenService }}>
      {props.children}
    </GlobalStateContext.Provider>
  );
};