import { useContext } from 'react';
import { GlobalStateContext } from '../../globalState';
import { useActor } from '@xstate/react';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SummaryCheckIn = () => {
  const globalServices = useContext(GlobalStateContext);
  const [service, send] = useActor(globalServices.persistedLeaveWhenService);

  let goToDetail = () => {
    send({type:'DETAIL_CHECKIN'});
  };

  let timeMinutes = (service.context.parameters.results as any).steps
    .checkIn.durationMinutes;

  return (<div className="flex flex-row pb-4">
        <div className="basis-1/4 text-4xl">
            <FontAwesomeIcon className="mt-3" icon={faSuitcase} />
        </div>
        <div className="basis-1/2 text-left">
          <div>{timeMinutes} Minute Check In</div>
          <div className="text-sm opacity-70">Assuming 1 Checked Bag</div>
        </div>
        <div className="basis-1/4">
          <button onClick={goToDetail} disabled={true}  className="btn text-slate-300 hover:text-white transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none h-9 py-2 px-4">...</button>
        </div>
      </div>
)};