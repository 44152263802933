import { useEffect, useState } from 'react';
import axios from 'axios';
import { FlightMatch } from '../../../api/types/FlightMatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onFlightChange: (flight: FlightMatch) => void;
  };

export const FlightLookup = ({onFlightChange} : Props) => {
  const [value, setValue] = useState("");
  const [anySearch, setAnySearch] = useState(false);
  const [flightMatch, setFlightMatch] = useState({} as FlightMatch);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (q:any) => {
        try {
          const { data } = await axios.get('/api/flights?q='+q);
          setLoading(false);
          setAnySearch(true);
          let result = data as FlightMatch;
          setFlightMatch(result);
          onFlightChange(result);  
        } catch (err) {
          setLoading(false);
        }
      };

    if (value && value.length > 3) {
      const delay = setTimeout(() => {
        setLoading(true);
        fetchData(value)
      }, 750);
      return () => clearTimeout(delay);
    } else {
        let result = {} as FlightMatch;
        setAnySearch(false);
        setFlightMatch(result);
        onFlightChange(result);
    }
  // eslint-disable-next-line
  }, [value]);

  return (
    <div className="flightLookup grow">
        <input type="text" value={value} placeholder="Flight Number..." onChange={(e) => setValue(e.target.value)} className="text-center form-input w-full text-lg"></input>
        { loading && <div className="text-slate-200 italic flight-search-loading-indicator text-sm pt-2">locating your flight...</div>}
        { anySearch && !loading && <div className="text-slate-300 flightHint text-sm pt-2">{ flightMatch.valid ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} /> }{ flightMatch.valid ? ' ' + flightMatch.fullName : ' Unknown Flight' }</div> }
    </div>
)};