import { createMachine } from 'xstate';
import { history } from "./history";
import axios from "axios";
import moment from 'moment';

// How much time, in minutes, to store the full results
// (Used to automatically reload the detail page using this prior information)
const FULL_RESULT_CACHE_TIME_MINUTES:number = 5;

export const leaveWhenMachine = createMachine({
    predictableActionArguments: true,
    id: 'leavewhen',
    initial: 'preload',
    context: {
        parameters: {
            flight: "",
            departureAirportName: "",
            departureTime: "",
            startLocation: "",
            originAirportLocation: "",
            drivingDurationMinutes: -1,
            trafficRefreshTime: moment(),
            results: {}
        }
    },
    states: {
      preload: {
        on: {
          START_FRESH: 
          {
            target: 'startDefault'
          },
          RELOOKUP: 
          {
            target: 'loading'
          },
          RELOAD_DETAIL:
          {
            target: 'mainDisplay'
          }
        },
        invoke: {
          src: (context) => async (send) => {
            if (!context.parameters.departureTime || !context.parameters.results)
            {
              send({type:'START_FRESH'});
            } else {
              var now = moment();
              var departureTime = moment(context.parameters.departureTime);

              if (now.isAfter(departureTime))
              {
                // After departure time, prompt user to enter new information
                send({type:'START_FRESH'});
              } else {
                var trafficRefreshTime = moment(context.parameters.trafficRefreshTime);
                var reloadCutoff = moment().add(-1 * FULL_RESULT_CACHE_TIME_MINUTES,'minutes');
                if (trafficRefreshTime.isBefore(reloadCutoff))
                {
                  // Traffic information is outdated - reload
                  send({type:'RELOOKUP'});
                } else {
                  // Traffic information is still fresh - display again without recalculation
                  send({type:'RELOAD_DETAIL'});
                }
              }
            }
          }
        }
      },
      startDefault: {
        on: {
          LOOKUP: 
          {
            target: 'loading',
          },
        },
        invoke: {
            src: "initialize"
          },
      },
      loading: {
        on: {
          LOOKUP_SUCCESS: {
            target: 'mainDisplay',
            actions: ['persist']
          },
          FAILHOME: {
            target:'startDefault',
            actions: ['resetHome']
          },
        },
        invoke: {
            src: (context) => async (send) => {
              history.push('/lookup');
              navigator.geolocation.getCurrentPosition(function(position) {
                var currentLocation = position.coords.latitude + ', ' + position.coords.longitude
                context.parameters.startLocation = currentLocation;
                var start = context.parameters.startLocation;
                var end = context.parameters.originAirportLocation;
                axios.get('/api/timing?start='+start+'&originAirport='+end).then((response) => {
                    context.parameters.drivingDurationMinutes = response.data.steps.transportation.durationMinutes;
                    context.parameters.trafficRefreshTime = moment();
                    send({type: 'LOOKUP_SUCCESS'});
                  }).catch(function(error) {
                    var message = error?.response?.data?.errorMessage || "We're having trouble looking up information for this one.";
                    message += " Please try again later.";
                    send({type:'FAILHOME'})
                    alert(message);
                  });
              }, function(error) {
                alert('Please permit us to access your location so that we can calculate driving distance (Note that future versions will permit entry of a starting location).');
                send({type:'FAILHOME'})
              });
            }
          },
      },
      mainDisplay: {
        entry: ['recalculate','persist'],
        on: {
          RESETHOME: {
            target:'startDefault',
            actions: ['resetHome']
          },
          DETAIL_TRANSPORTATION: 'detailTransportation'
        },
        invoke: {
          src: () => async(send) => {
            history.push('/results');
            document.getElementById("bg-video")!.classList.add('backdrop-opacity-50');
            document.getElementById("bg-video")!.classList.add('main-results-open');
            document.getElementById("video-content")!.classList.add('opacity-50');
          }
        }
      },
      detailTransportation: {
        on: {
          BACK: 'mainDisplay'
        },
        invoke: {
          src: () => async(send) => {
            history.push('/detail-transportation');
          }
        }
      } 
    }
  }, {
    actions: {
      recalculate: (context,event) => {
        if (!context.parameters.results)
        {
          context.parameters.results = {
            leaveTime: "",
            dateKnown: false,
            steps: {
                'transportation': {
                    mode: "driving",
                    durationMinutes: 60,
                },
                'checkIn': {
                    checkInMode: "online",
                    checkedBags: "none",
                    durationMinutes: 15,
                },
                'security': {
                    precheck: "no",
                    durationMinutes: 30,
                },
                'padding':{
                    travellingAlone: "yes",
                    extraTimeMode: "medium",
                    durationMinutes: 90,
                }
            }
          };
        }

        var results = (context.parameters.results as any);
        results.steps.transportation.durationMinutes = context.parameters.drivingDurationMinutes;

        var allSteps = Object.keys(results.steps);

        let i = 0;
        let totalMinutes = 0;

        while (i < allSteps.length) {
            var stepKey = allSteps[i];
            var stepMinutes = results.steps[stepKey].durationMinutes;
            totalMinutes += stepMinutes;
            i++;
        }

        var departureTimeRaw = context.parameters.departureTime;
        var departureTime = moment(departureTimeRaw);
        results.leaveTime = departureTime.add(-1 * totalMinutes,'minutes');

      },
      resetHome: (context,event) => {
        history.push('/');
        document.body.className.replace('main-results-open','');
      }
    },
    services: {
        initialize: async(ctx, event) => {
            history.push('/');
        }
  }});